.btn,
.jumbotron,
.nav :hover {
  border-radius: 0px;
}

body {
  background: #CAD5E2;
}

#containn{
  background: rgba(255,255,255,0.5);
  backdrop-filter: blur(5px);
}

#navbar-custom{
  background: rgba(255,255,255,0.5);
  backdrop-filter: blur(5px);
}

#responsive-navbar-nav{
  justify-content: flex-end;

}

section {
  padding: 60px 0 20px 0;
}

section .section-title {
  text-align: center;
  color: #242B2E;
  margin-bottom: 50px;
  text-transform: uppercase;
}
#footer {
  background: #242B2E !important;
  position: relative;
  bottom: 0;
}
#footer h5{
padding-left: 10px;
  border-left: 3px solid #eeeeee;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color:#ffffff;
}
#footer a {
  color: #ffffff;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
#footer ul.social li{
padding: 3px 0;
}
#footer ul.social li a i {
  margin-right: 5px;
font-size:25px;
-webkit-transition: .5s all ease;
-moz-transition: .5s all ease;
transition: .5s all ease;
}
#footer ul.social li:hover a i {
font-size:30px;
margin-top:-10px;
}
#footer ul.social li a,
#footer ul.quick-links li a{
color:#ffffff;
}
#footer ul.social li a:hover{
color:#eeeeee;
}
#footer ul.quick-links li{
padding: 3px 0;
-webkit-transition: .5s all ease;
-moz-transition: .5s all ease;
transition: .5s all ease;
}
#footer ul.quick-links li:hover{
padding: 3px 0;
margin-left:5px;
font-weight:700;
}
#footer ul.quick-links li a i{
margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
  font-weight: 700;
}

@media (max-width:767px){
#footer h5 {
  padding-left: 0;
  border-left: transparent;
  padding-bottom: 0px;
  margin-bottom: 10px;
}
}

#border-3 {
  border-width:3px !important;
}

#label-left {
  float: left
}

#span-right {
  display: block;
  overflow: hidden;
  padding: 0 4px 0 6px
}
#input-right {
  width: 100%
}

#searchBox{
  min-width: 200px;
  width: 250px;
  border-radius: 3px;
}

.verti-timeline {
  border-left: 3px dashed #f6f6f6;
  margin: 0 10px;
}

.list-inline, .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.event-list {
  position: relative;
  padding: 0 0 40px 30px;
}

.event-timeline-dot {
  position: absolute;
  left: -9px;
  top: 0;
  z-index: 9;
  font-size: 16px;
}

li{
  color: #242B2E;
}

.Search {
  font-family: sans-serif;
  text-align: center;
}

#react-select-2-listbox{
  color: #242B2E;
}

#react-select-3-listbox{
  color: #242B2E;
}

#react-select-4-listbox{
  color: #242B2E;
}

#searchbtn{
  border-radius: 10px;
}